import { CanActivateFn, Router } from '@angular/router';
import { iRQp } from '../models/qp.interface';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { eRRoles } from '../enums/eRRoles';
// Snack Bar
import { MatSnackBar } from '@angular/material/snack-bar';

export const qpGuard: CanActivateFn = (route, state) => {
  const ro = inject(Router);
  const authS = inject(AuthService);
  const qp = route.queryParams as iRQp;
  const snackBar = inject(MatSnackBar);

  if (qp?.assigned) {
    if (
      authS.isSelMa && (
        (authS.userDetails?.role === eRRoles.manager) ||
        (authS.userDetails?.role === eRRoles.owner)
      )
    ) {
      return true;
    } else {
      return ro.navigate(['/']);
    }
  } else if (qp?.mechanic || qp?.userId || qp?.vehicleId) {
    return true;
  } else {
    snackBar.open('You do not have permission to access this page.', 'OK', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center'
    });
    return ro.navigate(['/']);
  }
};
export const managerGuard: CanActivateFn = (route, state) => {
  const ro = inject(Router);
  const authS = inject(AuthService);
  const snackBar = inject(MatSnackBar);
  if (
    authS.isSelMa && (
      (authS.userDetails?.role === eRRoles.manager) ||
      (authS.userDetails?.role === eRRoles.owner)
    )
  ) {
    return true;
  } else if (authS.isSelMe && (
    (authS.userDetails?.role === eRRoles.manager) ||
    (authS.userDetails?.role === eRRoles.owner) ||
    (authS.userDetails?.role === eRRoles.mechanic))) {
    return true;
  } else {
    snackBar.open('You do not have permission to access this page.', 'OK', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center'
    });
    return ro.navigate(['/']);
  }
};
